import React, { Component } from "react";
import "./App.css";
import Fade from "react-reveal";
import Footer from "./Components/Footer";

class Homelab extends Component {
   render() {
      return (
         <div className="App">
            <div className="proj">
               <Fade bottom><h3>Homelab</h3></Fade>
               <Fade bottom duration={1200}><p>I orignally intended for this page to be about my 'homelab' that I had built and running, but I guess this is going to be more of a short memorial to what was.</p>
               <p>At the start of covid, I was bored, so I started working in other operating systems then Windows. I had been working with Ubuntu since I was 14 on a old Compaq with less cores then I have wheels on my car. Safe to say, I was not good at running Linux. But I wanted to be, and I discovered  <a href="https://reddit.com/r/homelab">r/homelab</a> on reddit. And I thought, wow that's cool I want to do that. So early covid, when I had a ton of time, I went on Facebook marketplace and bought a 50 lbs server (HP Proliant G6 2U) with dual Intel Xeons, 64 GB of DDR3 RAM, 8 bays for hard drives, and two <b>hulking</b> 450-watt power supplies. I probably should not own this server. Enterprise grade hardware is expensive, and resource intensive, and I love it, but is extremely powerful can run away from you without a enterprise grade budget. It is a HP ProLiant from ~2012 with the caveat that it can only run HP supported drives. Which is super restraining. If you do run a drive that is not HP approved, it will run the fans as fast and loud as it can, and everyone in your house will be able to hear it. How do I know this, because I did it. And my power bill went up $300 dollars. I also had to mod a battery into it to make the hdd port map (at least I think that’s what it's called) be non-volatile and not reset after every boot. Now, as you can tell, I was not very responsible with this. So, I don't run it anymore. However, I can definitely say that it was a learning experience as I now understand Proxmox and ESXi way better. In the short time I had run it, I ran two NAS servers, a website, a Ubuntu playground which I specifically used to run old versions of windows and nuke with viruses, and my first taste of the addictive poison that is Arch Linux.</p>
               <img src="https://i.imgur.com/ahWrquN.jpg" alt="picture of the lad" />
               <p>(on blocks because racks are expensive and wood to make one was even more expensive at the time)</p>
               <p>So now, about a year and some after I shut it down for the hopefully not last time, I have two dedicated NAS (network attached storage if that wasn't clear) servers which I use constantly, and a Raspberry Pi 3 running OctoPrint for my 3D printer. That's it. I save so much on power now, it's not even funny. I sacrificed my playgrounds for actual computers running Arch/Windows/Ubuntu/BSD with VirtualBox and it is so much better than it was. I typically run all of my computationally heavy stuff on my primary computer (I'll write a page about my PC at a future date, it's a lot) but otherwise I use old laptops, and old Inspiron with a i7 and runs smoother and more reliably then any of my other computers, and sometimes, if I need the GPU, an old Alienware X51 which I sometimes leave running for weeks processing video on its GT600 and lowish spec power supply. As you can tell, things are different and better. I now primary Arch Linux as my secondary OS and I can't really use a bare metal hypervisor like I'm used to, so I just pretend I never touched enterprise grade hardware and use my laptop like a good, non-power user for my classes.</p>
               <p>I don't plan on waking this monster up for a little while. So for now, sleep well sweet prince.</p>
               <h3>August 11th, 2022</h3>
               <p>This is a update to the above post. I didn't want to make a whole new page to feature this so I'm just going to append this.</p>
               <p>It is currently August 2022, and I have some good and bad news. To start with the bad, I still have not woken up the Proliant (and will not for the forseeable future). Sad, I know. But in much better news, I have finally found time to make a proper server using Proxmox. I like it much better then ESXi because I find it much more familiar for some reason. I'm using a old gaming PC I was given (old like 2012) and overall, it works pretty well. 16GB RAM on a Intel i7 3770 with a GTX 660 (which cannot be passed though RIP) and I'm pretty happy with its performance. I run Ubuntu server with docker and a dozen or so containers along with a few other VMs when I need them, and it works for me. In the future, I'd like to build a dedicated server using a Xeon E3-1245 and actual server grade components, but I am concerned about power now and a few hundred dollars added to my total at the end of the month does not sound appetizing. I want to upgrade my NAS eventually too to unRAID, but that is even further in the future. Synology is plug and play, so I'm just going to keep rocking this for now. My power draw averages ~45 Watts or a little over 1 kWh which I think is pretty good for the 330 Watt power supply. The GPU thing does annoy me, but it doesn't suck power so w/e i guess. I've been using this whole system to replace a tertiary computer I used for a few years which has a gen 1 i7 in it and strains when I need to switch operating systems. The poor thing would get rebooted 30+ times a week and I didn't feel like I could rely on it.</p>
               <p>Other than that, I think that’s all for this update. I will try and update again in the future because this is something I sincerely enjoy. I learned that most of the RAM in the Proliant is actually 4GB Registered ECC and there are like 16 sticks, so that will cut down on some of my operating costs if I build a server. I joke that when I get a "big boy job" the first thing I'll do is build one. But that’s a ways away. </p>
               <p>Anyway, that's just an update. I will update this page more frequently in the future. Until I don't.</p>
               <p>Until next time.</p></Fade>
            </div>
            <Footer />
         </div>
      );
   }
}

export default Homelab;